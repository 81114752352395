import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAgenda, IHospitalService, IManager, IPatient, KeycloakUserDTO } from 'app/shared/interfaces';
import { environment } from 'environments/environment';
import { CorePatientService } from '../patient/patient.service';
import { CoreKeycloakService } from '../keycloak/core-keycloak.service';
import { Observable, take, tap, forkJoin } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CoreManagerService {
    constructor(
        private _httpClient: HttpClient,private _patientService: CorePatientService,
        private _keycloakService: CoreKeycloakService,
    ) { }

    fetchByPhone(phone: string): Observable<IManager> {
        const endpoint = '/query/gestionnaireAgendas/getByPhone/';
        return this._httpClient.get<IManager>(
            environment.query.etablissement + endpoint + phone
        );
    }

    fetchByAgenda(codeAgenda: string): Observable<IManager> {
        const endpoint = '/query/gestionnaireAgendas/getAllGestionnaireAgendasByAgenda/';
        return this._httpClient.get<IManager>(
            environment.query.etablissement + endpoint + codeAgenda
        );
    }

    fetchByHospital(idhospital: string): Observable<IManager[]> {
        const endpoint = '/query/gestionnaireAgendas/getAllByEtablissement/';
        return this._httpClient.get<IManager[]>(
            environment.query.etablissement + endpoint + idhospital
        );
    }

    fetchById(id: string): Observable<IManager> {
        const endpoint = '/query/gestionnaireAgendas/getById/';
        return this._httpClient.get<IManager>(
            environment.query.etablissement + endpoint + id
        );
    }

    fetchByService(idService: string): Observable<IManager[]> {
        const endpoint =
            '/query/gestionnaireAgendas/getAllGestionnaireAgendasByEtablissementService/';
        return this._httpClient.get<IManager[]>(
            environment.query.etablissement + endpoint + idService
        );
    }

    postManager(payload: IManager): Observable<IManager> {
        const endpoint = '/commands/gestionnaireAgendas/create';
        return this._httpClient.post<IManager>(
            environment.commande.etablissement + endpoint,
            payload
        );
    }

    postManagerMap(payload: {
        gestionnaireAgenda: IManager;
        etablissementServices: IHospitalService[];
        agendas: IAgenda[];
    }): Observable<IManager> {
        const endpoint =
            '/commands/gestionnaireAgendas/createAndMapToServicesAndAgendas';
        return this._httpClient.post<IManager>(
            environment.commande.etablissement + endpoint,
            payload
        );
    }

    onCreate(): void {
        const patient = this.buildPatient();
        const manager = this.buildManager();
        const keycloakProfile = this.buildKeycloackProfile();
        this.createManager(patient, manager, keycloakProfile);
    }

    createManager(
        patient: IPatient,
        manager: IManager,
        keycloakProfile: KeycloakUserDTO
    ): void {
        forkJoin([
            this._patientService.postPatient(patient),
            this.postManager(manager),
            this._keycloakService.postKeycloakUser(keycloakProfile),
        ])
            .pipe(
                take(1),
                tap((data) => {
                    const result = data[1];
                    result.patient = data[0];
                })
            )
            .subscribe();
    }

    buildManager(): IManager {
        const payload: IManager = {
            codeEtablissement: '4CB1D196FBC54D3DA42D875239D9DBDAETAB',
            codePatient: '774286034',
            active: true,
            dateCreate: new Date().toISOString(),
            dateUpdate: new Date().toISOString(),
            userId: '',
        };
        return payload;
    }

    buildPatient(): IPatient {
        const payload: IPatient = {
            firstName: 'Jean',
            lastName: 'Sarr',
            telephone: { value: '774286034', periode: null, type: 'PHONE' },
            genre: 'MALE',
            email: 'jean@esp.sn',
            address: { address: 'Patte doie', city: 'Dakar', post: 'B70' },
            dateNaissance: '2000-12-06T11:43:44.525Z',
            username: '774286034',
            profilId: {
                profilId: 'GESTIONNAIRE',
                profilName: 'GESTIONNAIRE',
                organizationName: '4CB1D196FBC54D3DA42D875239D9DBDAETAB',
            },
            deceased: {
                deceasedBoolean: false,
                deceasedDateTime: '',
            },
            password: 'password',
            imageProfil: 'assets/images/avatars/person.png',
            enabled: true,
            emailVerified: true,
            totp: true,
            idpUserId: '',
            idpAlias: '',
            credentials: [],
            notBefore: 0,
            requiredActions: ['UPDATE_PASSWORD'],
            access: null,
            googleAth: 'CODE-GOOGLE',
            identifier: '',
            codeMedSen: '',
            useOn: null,
            contact: null,
            communication: '',
            maritalStatus: null,
            condition: true,
            locationState: null,
        };
        return payload;
    }

    buildKeycloackProfile(): KeycloakUserDTO {
        const payload: KeycloakUserDTO = {
            username: '760230775',
            firstName: 'Maguette',
            lastName: 'Seck',
            enabled: true,
            email: 'Maguette@esp.sn',
            credentials: [
                {
                    type: 'password',
                    value: 'passer',
                    temporary: false,
                },
            ],
            attributes: {
                profilId: ['MEDECIN'],
                profilName: ['MEDECIN'],
                telephone: ['760230775'],
                adresse: ['Patte doie'],
            },
            access: {
                impersonate: true,
                manage: true,
                manageGroupMembership: true,
                mapRoles: true,
                view: true,
            },
        };
        return payload;
    }
}
