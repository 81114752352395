import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mockIService } from 'app/mock-api/service/data';
import { IHospitalService, IService } from 'app/shared/interfaces';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CoreHospitalServiceService {

    constructor(
        private _httpClient: HttpClient,
    ) { }



    fetchServiceByMedecinAndHospital(idMedecin: string, idHospital: string): Observable<IHospitalService[]> {
        const endpoint = '/query/medecinServices/getByMedecinAndEtablissement';
        return this._httpClient.get<IHospitalService[]>(
            `${environment.query.medecin}${endpoint}/${idMedecin}/${idHospital}`
        );
    }


    fetchServiceById(idService: string): Observable<IService> {
        const endpoint = '/query/services/getById/';
        return this._httpClient.get<IService>(
            environment.query.etablissement + endpoint + idService
        );
    }


    fetchAvaibleServices(): Observable<IService[]> {
        const endpoint = '/query/services/allServices/';
        return this._httpClient.get<IService[]>(
            environment.query.etablissement + endpoint
        );
    }


    fetchActivatedServices(): Observable<IService[]> {
        const endpoint = '/query/services/getActived/';
        return this._httpClient.get<IService[]>(
            environment.query.etablissement + endpoint
        );
    }

    fetchByHospital(idHospital: string): Observable<IHospitalService[]> {
        const endpoint = '/query/etablissementServices/getAllActivedByEtablissement/';
        return this._httpClient.get<IHospitalService[]>(
            environment.query.etablissement + endpoint + idHospital
        );
    }


    postService(payload: IService): Observable<IService> {
        const endpoint = '/commands/services/create';
        return this._httpClient.post<IService>(
            environment.commande.etablissement + endpoint,
            payload
        );
    }

    updateService(payload: IService): Observable<IService> {
        const endpoint = '/commands/services/update';
        return this._httpClient.put<IService>(
            environment.commande.etablissement + endpoint,
            payload
        );
    }

    postHospitalService(payload: IHospitalService): Observable<IHospitalService> {
        const endpoint = '/commands/etablissementServices/create';
        return this._httpClient.post<IHospitalService>(
            environment.commande.etablissement + endpoint,
            payload
        );
    }


    activateHospitalService(idHospitalService: string): Observable<boolean> {
        const endpoint = '/commands/etablissementServices/actived/';
        return this._httpClient.get<boolean>(
            environment.commande.etablissement + endpoint + idHospitalService,
        );
    }

    desactivateHospitalService(idHospitalService: string): Observable<boolean> {
        const endpoint = '/commands/etablissementServices/desactived/';
        return this._httpClient.get<boolean>(
            environment.commande.etablissement + endpoint + idHospitalService,
        );
    }


    deleteHospitalService(idHospitalService: string): Observable<boolean> {
        const endpoint = '/commands/etablissementServices/delete/';
        return this._httpClient.delete<boolean>(
            environment.commande.etablissement + endpoint + idHospitalService,
        );
    }

    deleteService(idService: string): Observable<boolean> {
        const endpoint = '/commands/services/delete/';
        return this._httpClient.delete<boolean>(
            environment.commande.etablissement + endpoint + idService,
        );
    }

    //admin active service
    activateServiceById(idService: string): Observable<boolean> {
        const endpoint = '/commands/services/active/';
        return this._httpClient.get<boolean>(
            environment.commande.etablissement + endpoint + idService
        );
    }

    //admin desactive service
    unactivateServiceById(idService: string): Observable<boolean> {
        const endpoint = '/commands/services/desactive/';
        return this._httpClient.get<boolean>(
            environment.commande.etablissement + endpoint + idService
        );
    }

    getIconByName(name: string): string | null  {
        const found = mockIService.find(service => service.nomService.toLocaleLowerCase().includes(name.toLocaleLowerCase()));
        return found?.icon || null;
    };

}
