import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IChefIME } from 'app/shared/interfaces';
import { environment } from 'environments/environment';
import { Observable, map, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CoreChefIMEService {

    constructor(
        private _httpClient: HttpClient,
    ) { }

    fetchAllChefIMEs(): Observable<IChefIME[]> {
        const endpoint = '/query/chefIMEs/allChefIMEs';
        return this._httpClient.get<IChefIME[]>(
            environment.query.etablissement + endpoint
        );
    }

    fetchChefIMEByPhone(phone: string): Observable<IChefIME> {
        const endpoint = '/query/chefIMEs/getByPhone/';
        return this._httpClient.get<IChefIME[]>(
            environment.query.etablissement + endpoint + phone
        ).pipe(
            map((d: IChefIME[]) => [...d].sort((a,b) => new Date(b.dateCreate).getTime() - new Date(a.dateCreate).getTime())),
            map(d => d[0]),
        );
    }

    fetchChefIMEByidPatient(idPatient: string): Observable<IChefIME> {
        const endpoint = '/query/chefIMEs/getByPatient/';
        return this._httpClient.get<IChefIME[]>(
            environment.query.etablissement + endpoint + idPatient
        ).pipe(
            map((d: IChefIME[]) => [...d].sort((a,b) => new Date(b.dateCreate).getTime() - new Date(a.dateCreate).getTime())),
            map(d => d[0]),
        );
    }

    fetchChefIMEs(idHospital: string): Observable<IChefIME[]> {
        const endpoint = '/query/chefIMEs/getAllByCodeEtablissement/';
        return this._httpClient.get<IChefIME[]>(
            environment.query.etablissement + endpoint + idHospital
        );
    }

    postChefIME(payload: IChefIME): Observable<IChefIME> {
        const endpoint = '/commands/chefIMEs/createAndMap/';
        return this._httpClient.post<IChefIME>(
            environment.commande.etablissement + endpoint + payload.codeEtablissement,
            payload
        );
    }

    archiveChefIMEByHospital(chefIMEId: string): Observable<boolean> {
        const endpoint = `/commands/chefIMEs/archive/${chefIMEId}`;
        return this._httpClient.get<boolean>(
            environment.commande.etablissement + endpoint,
        );
    }

    activeChefIME(chefIMEId: string): Observable<boolean> {
        const endpoint = `/commands/chefIMEs/active/${chefIMEId}`;
        return this._httpClient.get<boolean>(
            environment.commande.etablissement + endpoint,
        );
    }
}
