import { FuseNavigationItem } from '@fuse/components/navigation';

export const adminNavigation: FuseNavigationItem[] = [

    {
        id      : 'dashboard',
        title   : '',
        subtitle: '',
        type    : 'group',
        icon    : 'heroicons_outline:chart-pie',
        classes: {
            title: 'text-gray-100'
        },
        children: [
            {
                id      : 'dashboard.dashboard',
                title   : 'Accueil',
                type    : 'basic',
                icon    : 'heroicons_outline:home',
                link : '/app/dashboard/admin',
            },
            {
                id      : 'dashboard.organisation',
                title   : 'Etablissement',
                type    : 'basic',
                icon    : 'heroicons_outline:office-building',
                link: '/app/admin/organisation'
            },
            {
                id      : 'dashboard.utilisateur',
                title   : 'Utilisateur',
                type    : 'basic',
                icon    : 'heroicons_outline:user',
                link: '/app/admin/utilisateur'
            },
        ]
    },
    {
        id      : 'configuration',
        title   : '',
        subtitle: '',
        type    : 'group',
        icon    : 'heroicons_outline:cog',
        classes: {
            title: 'text-gray-100'
        },
        children: [
            {
                id      : 'configuration.module',
                title   : 'Compte',
                type    : 'basic',
                icon    : 'heroicons_outline:user',
                link: '/app/setting'
            },
            {
                id      : 'configuration.debug',
                title   : 'Debug',
                type    : 'basic',
                icon    : 'heroicons_outline:cog',
                link: '/app/admin/debug'
            }

        ]
    },


];
