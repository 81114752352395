<div class="flex flex-col sm:flex-row w-screen h-screen items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0">
    <div class="sm:flex sm:items-center sm:justify-end w-full sm:w-auto md:h-full md:w-2/5 p-8 sm:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card">
        <div class="w-full mx-auto sm:mx-0">
            <!-- Logo -->
            <div class="w-30">
                <img src="../../../../assets/images/logo/logo-text.png" alt="">
            </div>

            <!-- Title -->
            <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">Connexion</div>

            <!-- Alert -->
            <fuse-alert
                class="mt-8 -mb-4"
                *ngIf="showAlert"
                [appearance]="'outline'"
                [showIcon]="false"
                [type]="alert.type"
                [@shake]="alert.type === 'error'">
                {{alert.message}}
            </fuse-alert>

            <!-- Sign in form -->
            <form
                class="mt-8"
                [formGroup]="signInForm"
                #signInNgForm="ngForm">

                <!-- Téléphone field -->
                <mat-form-field class="w-full">
                    <mat-label>Utilisateur</mat-label>
                    <input
                        id="email"
                        matInput
                        [formControlName]="'email'">
                    <mat-error *ngIf="signInForm.get('email').hasError('required')">
                        utilisateur requis
                    </mat-error>
                </mat-form-field>

                <!-- Password field -->
                <mat-form-field class="w-full">
                    <mat-label>Mot de passe</mat-label>
                    <input
                        id="password"
                        matInput
                        type="password"
                        [formControlName]="'password'"
                        #passwordField>
                    <button
                        mat-icon-button
                        type="button"
                        (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                        matSuffix>
                        <mat-icon
                            class="icon-size-5"
                            *ngIf="passwordField.type === 'password'"
                            [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                        <mat-icon
                            class="icon-size-5"
                            *ngIf="passwordField.type === 'text'"
                            [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                    </button>
                    <mat-error>
                        Mot de passe requis
                    </mat-error>
                </mat-form-field>

                <!-- Actions -->
                <div class="inline-flex items-end justify-between w-full mt-1.5">
                    <mat-checkbox
                        [formControlName]="'rememberMe'">
                        Se souvenir de moi
                    </mat-checkbox>
                    <a
                        class="text-md font-medium text-greenWhite hover:text-greenBlack hover:underline"
                        [routerLink]="['/forgot-password']">mot de passe oublié?
                    </a>
                </div>

                <!-- Submit button -->
                <button
                    class="fuse-mat-button-large w-full mt-6 bg-greenBlack text-white"
                    mat-flat-button
                    [disabled]="signInForm.disabled"
                    (click)="signIn()">
                    <span *ngIf="!signInForm.disabled">
                        Se connecter
                    </span>
                    <mat-progress-spinner
                        *ngIf="signInForm.disabled"
                        [diameter]="24"
                        mode="indeterminate">
                    </mat-progress-spinner>
                </button>

                <!-- Separator -->
                <div class="flex items-center mt-8">
                    <div class="flex-auto mt-px border-t"></div>
                    <div class="mx-2 text-secondary">Ou continuer avec</div>
                    <div class="flex-auto mt-px border-t"></div>
                </div>

                <!-- Single sign-on buttons -->
                <div class="flex items-center mt-8 space-x-4">
                    <button
                        class="flex-auto text-[#3b5998] hover:bg-[#3b5998] hover:text-white"
                        type="button"
                        mat-stroked-button>
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'feather:facebook'"></mat-icon>
                    </button>
                    <button
                        class="flex-auto text-[#1DA1F2] hover:bg-[#1DA1F2] hover:text-white"
                        type="button"
                        mat-stroked-button>
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'feather:twitter'"></mat-icon>
                    </button>
                    <button
                        class="flex-auto text-[#24292e] hover:bg-[#24292e] hover:text-white"
                        type="button"
                        mat-stroked-button>
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'feather:github'"></mat-icon>
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div class="relative hidden sm:flex flex-auto items-center justify-center w-3/5 h-full overflow-hidden bg-greenBlack dark:border-l">
        <div class="relative flex w-full h-full items-end px-4 pb-10 pt-60 sm:pb-16 md:justify-center lg:pb-24 bg-gray-50 sm:px-6 lg:px-8">
            <div class="absolute inset-0">
                <img class="object-cover object-top w-full h-full" src="assets/images/login.png" alt="" />
            </div>
            <div class="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>

            <div class="relative">
                <div class="w-full mx-auto">
                    <h3 class="text-4xl font-bold text-white">Bienvenue sur la plateforme MEDSEN</h3>
                    <h3 class="text-base text-gray-400">Veuillez vous connecter afin d'accéder à votre espace</h3>
                    <ul class="grid grid-cols-1 mt-10 sm:grid-cols-2 gap-x-8 gap-y-4">
                        <li class="flex items-center space-x-3">
                            <div class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 bg-greenBlack rounded-full">
                                <svg class="w-3.5 h-3.5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                </svg>
                            </div>
                            <a href="https://medsen.sn/" target="_blank" class="text-lg font-medium text-white hover:underline"> Site de Medsen </a>
                        </li>
                        <li class="flex items-center space-x-3">
                            <div class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 bg-greenBlack rounded-full">
                                <svg class="w-3.5 h-3.5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                </svg>
                            </div>
                            <a href="https://www.linkedin.com/posts/medsen_santaez-connectathon-startup-activity-7227725631201914880-ReKw?utm_source=share&utm_medium=member_desktop"
                                class="text-lg font-medium text-white hover:underline"
                                target="_blank"
                            > Connectathon IHE-Europe 2024 </a>
                        </li>
                        <li class="flex items-center space-x-3">
                            <div class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 bg-greenBlack rounded-full">
                                <svg class="w-3.5 h-3.5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                </svg>
                            </div>
                            <a href="https://www.linkedin.com/posts/medsen_engagement-systaeymes-reconnaissance-activity-7227774952387547136-wsBV?utm_source=share&utm_medium=member_desktop"
                                class="text-lg font-medium text-white hover:underline"
                                target="_blank"
                            > IHE Connectathon SEAL </a>
                        </li>
                        <li class="flex items-center space-x-3">
                            <div class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 bg-greenBlack rounded-full">
                                <svg class="w-3.5 h-3.5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                </svg>
                            </div>
                            <span class="text-lg font-medium text-white"> Santé scolaire </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
