import { FuseNavigationItem } from '@fuse/components/navigation';

export const academyNavigation: FuseNavigationItem[] = [

    {
        id      : 'dashboard',
        title   : '',
        subtitle: '',
        type    : 'group',
        icon    : 'heroicons_outline:chart-pie',
        classes: {
            title: 'text-gray-100'
        },
        children: [
            {
                id      : 'dashboard.home',
                title   : 'Accueil',
                type    : 'basic',
                icon    : 'heroicons_outline:home',
                link : '/app/dashboard/chef-academy',
            },
            {
                id      : 'dashboard.medecins',
                title   : 'Elèves',
                type    : 'basic',
                icon    : 'heroicons_outline:user-group',
                link : '/app/school/student',
            },
            {
                id      : 'dashboard.services',
                title   : 'Rendez-vous',
                type    : 'basic',
                icon    : 'mat_outline:access_time',
                link: '/app/school/reservation'
            },
            {
                id      : 'dashboard.visit',
                title   : 'Visites medicales',
                type    : 'basic',
                icon    : 'mat_outline:date_range',
                link: '/app/school/visit'
            },
        ]
    },
    {
        id      : 'configuration',
        title   : '',
        subtitle: '',
        type    : 'group',
        icon    : 'heroicons_outline:cog',
        classes: {
            title: 'text-gray-100'
        },
        children: [
            {
                id      : 'configuration.module',
                title   : 'Compte',
                type    : 'basic',
                icon    : 'heroicons_outline:user',
                link: '/app/setting'
            }

        ]
    },


];
