import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IHospital, IHospitalType } from 'app/shared/interfaces';
import { IHospitalSousType } from 'app/shared/interfaces/sous_type_etablissement';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CoreHospitalService {

    constructor(
        private _httpClient: HttpClient,
    ) { }



    fetchHospitals(): Observable<IHospital[]>{
        const endpoint = '/query/etablissements/allEtablissements/';
        return this._httpClient.get<IHospital[]>(
            environment.query.etablissement + endpoint
        );
    }


    fetchHospitalTypes(): Observable<IHospitalType[]>{
        const endpoint = '/query/typeEtablissements/allTypeEtablissements/';
        return this._httpClient.get<IHospitalType[]>(
            environment.query.etablissement + endpoint
        );
    }

    fetchTypeHospitalById(idHospitalTpe: string): Observable<IHospitalType>{
        const endpoint = '/query/typeEtablissements/getById/';
        return this._httpClient.get<IHospitalType>(
            environment.query.etablissement + endpoint + idHospitalTpe
        );
    }

    fetchHospitalById(idHospital: string): Observable<IHospital>{
        const endpoint = '/query/etablissements/getById/';
        return this._httpClient.get<IHospital>(
            environment.query.etablissement + endpoint + idHospital
        );
    }

    postHospital(payload: IHospital): Observable<IHospital>{
        const endpoint = '/commands/etablissements/create';
        return this._httpClient.post<IHospital>(
            environment.commande.etablissement + endpoint,
            payload
        );
    }

    updateHospital(payload: IHospital): Observable<IHospital>{
        const endpoint = '/commands/etablissements/update';
        return this._httpClient.put<IHospital>(
            environment.commande.etablissement + endpoint,
            payload
        );
    }

    postHospitalType(payload: IHospitalType): Observable<IHospitalType>{
        const endpoint = '/commands/typeEtablissements/create';
        return this._httpClient.post<IHospitalType>(
            environment.commande.etablissement + endpoint,
            payload
        );
    }

    deleteHospitalType(id: string): Observable<boolean>{
        const endpoint = '/commands/typeEtablissements/delete/';
        return this._httpClient.delete<boolean>(
            environment.commande.etablissement + endpoint + id
        );
    }

    archiveHospital(etsId: string): Observable<boolean> {
        const endpoint = `/commands/etablissements/archive/${etsId}`;
        return this._httpClient.get<boolean>(
            environment.commande.etablissement + endpoint,
        );
    }

    // SOUS TYPE ETABLISSEMENT
    fetchHospitalSouslType(): Observable<IHospitalSousType[]>{
        const endpoint = '/query/sousTypeEtablissements/all/';
        return this._httpClient.get<IHospitalSousType[]>(
            environment.query.etablissement + endpoint
        );
    }

    fetchHospitalSousTypeById(idHospitalSousTpe: string): Observable<IHospitalSousType>{
        const endpoint = '/query/sousTypeEtablissements/getById/';
        return this._httpClient.get<IHospitalSousType>(
            environment.query.etablissement + endpoint + idHospitalSousTpe
        );
    }

    fetchHospitalSousTypeByTypeEtablissement(idHospitalTpe: string): Observable<IHospitalSousType>{
        const endpoint = '/query/sousTypeEtablissements/findAllByTypeEtablissements/';
        return this._httpClient.get<IHospitalSousType>(
            environment.query.etablissement + endpoint + idHospitalTpe
        );
    }

    postHospitalSousType(payload: IHospitalSousType): Observable<IHospitalSousType>{
        const endpoint = '/commands/sousTypeEtablissements/create';
        return this._httpClient.post<IHospitalSousType>(
            environment.commande.etablissement + endpoint,
            payload
        );
    }

    deleteHospitalSousType(id: string): Observable<boolean>{
        const endpoint = '/commands/sousTypeEtablissements/delete/';
        return this._httpClient.delete<boolean>(
            environment.commande.etablissement + endpoint + id
        );
    }

}
