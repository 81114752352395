import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api';

@Injectable({
    providedIn: 'root'
})
export class ServiceRequestsMockApi
{
    private _serviceRequests: any = [];

    constructor(private _fuseMockApiService: FuseMockApiService) {
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ ServiceRequests - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/serviceRequests')
            .reply(() => [200, cloneDeep(this._serviceRequests)]);

        // -----------------------------------------------------------------------------------------------------
        // @ ServiceRequest - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/serviceRequests/serviceRequest')
            .reply(({request}) => {
                const id = request.params.get('id');
                const serviceRequests = cloneDeep(this._serviceRequests);
                const serviceRequest = serviceRequests.find(item => item.id === id);
                return [200, serviceRequest];
            });
    }
}
