import { IPatient } from 'app/shared/interfaces';

export const mockAdmin: IPatient = {
    id: '11',
    username: '778678298',
    firstName: 'Medsen',
    lastName: 'Admin',
    enabled: true,
    email: 'admin@medsen.sn',
    imageProfil: 'assets/images/avatars/person.png',
    telephone: {
        type: 'PHONE',
        periode: null,
        value: '778678298'
    },
    profilId: {
        profilId: 'ADMIN',
        profilName: 'ADMIN',
        organizationName: 'Medsen'
    },

    deceased: {
        deceasedBoolean: false,
        deceasedDateTime: '2023-09-12T12:00:00Z'
    },
    dateNaissance: '1995-07-20T00:00:00Z',
    genre: 'MALE',
    emailVerified: true,
    userId: null,
    dateCreate: '2023-05-24T11:42:18.334Z',
    dateUpdate: '2023-05-24T11:42:18.334Z',

    idpUserId: '',
    idpAlias: '',
    credentials: [],
    notBefore: 0,
    requiredActions: ['UPDATE_PASSWORD'],
    access: null,
    googleAth: 'CODE-GOOGLE',
    codeMedSen: 'SN2991990N-45334',
    useOn: null,
    address: null,
    contact: null,
    communication: '',
    maritalStatus: null,
    condition: true,
    locationState: null,
    totp: true,
    password: 'passer',
};

export const mockMedecin = {
    id: '13',
    username: '777484817',
    firstName: 'Daouda',
    lastName: 'Faye',
    email: 'Daouda.Faye@medsen.com',
    imageProfil: 'assets/images/avatars/person.png',
    profilId: {
        profilId: 'MEDECIN',
        profilName: 'MEDECIN',
        organizationName: 'IME Dakar'
    },
    genre: 'MALE',
    deceased: {
        deceasedBoolean: false,
        deceasedDateTime: '2023-09-12T12:00:00Z'
    },
    telephone: {
        type: 'PHONE',
        periode: null,
        value: '777484817'
    },
    password: null,
    totp: true,
    enabled: true,
    emailVerified: true,
    dateNaissance: '1995-07-20T00:00:00Z',

    userId: 'user1',
    dateCreate: '2023-09-12T12:00:00Z',
    dateUpdate: '2023-09-12T12:00:00Z',
    idpUserId: '',
    idpAlias: '',
    credentials: [],
    notBefore: 0,
    requiredActions: ['UPDATE_PASSWORD'],
    access: null,
    googleAth: 'CODE-GOOGLE',
    identifier: 'identifier-ihe',
    codeMedSen: 'SN2991990N-85374',
    useOn: null,
    address: null,
    contact: null,
    communication: '',
    maritalStatus: null,
    condition: true,
    locationState: null,
};


export const mockChefIME = {
    id: '14',
    username: '776486024',
    firstName: 'Matar',
    lastName: 'Diop',
    email: 'Matar.Diop@medsen.com',
    imageProfil: 'assets/images/avatars/person.png',
    profilId: {
        profilId: 'CHEF_IME',
        profilName: 'CHEF_IME',
        organizationName: 'IME Dakar'
    },
    genre: 'MALE',
    deceased: {
        deceasedBoolean: false,
        deceasedDateTime: '2023-09-12T12:00:00Z'
    },
    telephone: {
        type: 'PHONE',
        periode: null,
        value: '776486024'
    },
    enabled: true,
    emailVerified: true,
    dateNaissance: '1995-07-20T00:00:00Z',

    userId: 'user1',
    dateCreate: '2023-09-12T12:00:00Z',
    dateUpdate: '2023-09-12T12:00:00Z',

    password: 'passer123',
    totp: true,
    idpUserId: '',
    idpAlias: '',
    credentials: [],
    notBefore: 0,
    requiredActions: ['UPDATE_PASSWORD'],
    access: null,
    googleAth: 'CODE-GOOGLE',
    identifier: 'identifier-ihe',
    codeMedSen: 'SN2902990N-85374',
    useOn: null,
    address: null,
    contact: null,
    communication: '',
    maritalStatus: null,
    condition: true,
    locationState: null,
};

export const mockInfirmier = {
    id: '15',
    username: 'ntd',
    firstName: 'Ndeye Talla',
    lastName: 'Dioum',
    email: 'ntd@medsen.com',
    imageProfil: 'assets/images/avatars/person.png',
    profilId: {
        profilId: 'CHEF_ETAB_SCOLAIRE',
        profilName: 'CHEF_ETAB_SCOLAIRE',
        organizationName: 'Lycée Blaise Diagne'
    },
    genre: 'FEMALE',
    deceased: {
        deceasedBoolean: false,
        deceasedDateTime: '2023-09-12T12:00:00Z'
    },
    telephone: {
        type: 'PHONE',
        periode: null,
        value: '778458802'
    },
    enabled: true,
    emailVerified: true,
    dateNaissance: '1995-07-20T00:00:00Z',

    userId: 'user1',
    dateCreate: '2023-09-12T12:00:00Z',
    dateUpdate: '2023-09-12T12:00:00Z',

    password: 'passer123',
    totp: true,
    idpUserId: '',
    idpAlias: '',
    credentials: [],
    notBefore: 0,
    requiredActions: ['UPDATE_PASSWORD'],
    access: null,
    googleAth: 'CODE-GOOGLE',
    identifier: 'identifier-ihe',
    codeMedSen: 'SN2902990N-85374',
    useOn: null,
    address: null,
    contact: null,
    communication: '',
    maritalStatus: null,
    condition: true,
    locationState: null,
};

