// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    env: 'env staging',
    // keycloakConfig: {
    //     url: 'http://localhost:8080',
    //     realm: 'master',
    //     clientId: 'angular-client'
    // },
    baseUrl: 'https://staging.medsen.sn',
    keycloakConfig: {
        url: 'https://staging.medsen.sn:8443/auth',
        realm: 'MedSen',
        clientId: 'angular-client'
    },
    file: 'https://staging.medsen.sn/bandler-service/bandler',
    image: 'https://secure.medsen.sn/bandler-service/bandler',
    commande: {
        patient: 'https://staging.medsen.sn/pat-cmd',
        medecin: 'https://staging.medsen.sn/med-cmd',
        etablissement: 'https://staging.medsen.sn/etab-med-cmd',
        etablissementScolaire: 'https://staging.medsen.sn/etab-sco-cmd',
    },
    query: {
        patient: 'https://staging.medsen.sn/pat-query',
        medecin: 'https://staging.medsen.sn/med-query',
        etablissement: 'https://staging.medsen.sn/etab-med-query',
        etablissementScolaire: 'https://staging.medsen.sn/etab-sco-query',
    },
    auth: {
        authLink: 'https://staging.medsen.sn/medSenAuthServer'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
