import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IChefService } from 'app/shared/interfaces';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CoreChefServiceService {

    constructor(private _httpClient: HttpClient,) { }

    fetchByPhone(phone: string): Observable<IChefService[]> {
        const endpoint = '/query/chefServices/getByPhone/';
        return this._httpClient.get<IChefService[]>(
            environment.query.etablissement + endpoint + phone
        );
    }

    fetchByHospital(idHospital: string): Observable<IChefService[]> {
        const endpoint = '/query/chefServices/getByEtablissement/';
        return this._httpClient.get<IChefService[]>(
            environment.query.etablissement + endpoint + idHospital
        );
    }

    post(payload: IChefService, idService: string): Observable<IChefService> {
        const endpoint = '/commands/chefServices/createAndMap/';
        return this._httpClient.post<IChefService>(
            environment.commande.etablissement + endpoint + idService,
            payload
        );
    }

    isChefService(idChefService: string): Observable<boolean> {
        const endpoint = '/commands/chefServices/active/';
        return this._httpClient.get<boolean>(
            environment.commande.etablissement + endpoint + idChefService,
        );
    }

    isNotChefService(idChefService: string): Observable<boolean> {
        const endpoint = '/commands/chefServices/desactive/';
        return this._httpClient.get<boolean>(
            environment.commande.etablissement + endpoint + idChefService,
        );
    }

    fetchChefServiceById(id: string): Observable<IChefService> {
        const endpoint = '/query/chefServices/getById/';
        return this._httpClient.get<IChefService>(
            environment.query.etablissement + endpoint + id
        );
    }
}
