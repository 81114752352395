/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, take, map, Observable, ReplaySubject, switchMap, tap, of, forkJoin } from 'rxjs';
import { KeycloakProfile } from 'keycloak-js';
import { mockAdmin } from 'app/mock-api/admin/data';
import { IChefIME, IMedecin, IChefAcademy, IPatient, ProfilId, IHeaderInfo, IManager, IHospitalService } from 'app/shared/interfaces';
import { CorePatientService } from '../services/patient/patient.service';
import { CoreMedecinService } from '../services/medecin/medecin.service';
import { CoreHospitalService } from '../services/hospital/hospital.service';
import { CoreChefAcademyService } from '../services/chefAcademy/core-chef-academy.service';
import { CoreChefIMEService } from '../services/chefIME/core-chef-ime.service';
import { CoreManagerService } from '../services/manager/manager.service';
import { IChefService } from 'app/shared/interfaces/chefService';
import { CoreChefServiceService } from '../services/chefService/chef-service.service';
import { CoreHospitalServiceService } from '../services/hospitalService/core-hospital-service.service';
import { AuthService } from '../auth/services/auth.service';
import { IAuthResponse } from 'app/shared/interfaces/auth_response';


@Injectable({
    providedIn: 'root'
})
export class UserService
{
    private _user: ReplaySubject<IPatient> = new ReplaySubject<IPatient>(1);
    private _patient: ReplaySubject<IPatient> = new ReplaySubject<IPatient>(1);
    private _keycloakProfile: BehaviorSubject<KeycloakProfile> = new BehaviorSubject<KeycloakProfile>(null);
    private _userProfile: BehaviorSubject<IAuthResponse> = new BehaviorSubject<IAuthResponse>(null);
    private _medecin: BehaviorSubject<IMedecin> = new BehaviorSubject<IMedecin>(null);
    private _chefIME: BehaviorSubject<IChefIME> = new BehaviorSubject<IChefIME>(null);
    private _chefAcademy: BehaviorSubject<IChefAcademy> = new BehaviorSubject<IChefAcademy>(null);
    private _manager: BehaviorSubject<IManager> = new BehaviorSubject<IManager>(null);
    private _headerInfo: BehaviorSubject<IHeaderInfo> = new BehaviorSubject<IHeaderInfo>(null);
    private _chefService: BehaviorSubject<IChefService> = new BehaviorSubject<IChefService>(null);

    constructor(
        private _patientService: CorePatientService,
        private _medecinService: CoreMedecinService,
        private _hospitalService: CoreHospitalService,
        private _chefAcademyService: CoreChefAcademyService,
        private _chefIMEService: CoreChefIMEService,
        private _coreManagerService: CoreManagerService,
        private _coreChefService: CoreChefServiceService,
        private _coreHospitalServiceService: CoreHospitalServiceService,
        private _authService: AuthService,
    ){}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // getters
    get user$(): Observable<IPatient>{
        return this._user.asObservable();
    }
    get patient$(): Observable<IPatient>{
        return this._patient.asObservable();
    }
    get keycloakProfile$(): Observable<KeycloakProfile>{
        return this._keycloakProfile.asObservable();
    }
    get userProfile$(): Observable<IAuthResponse>{
        return this._userProfile.asObservable();
    }
    get medecin$(): Observable<IMedecin>{
        return this._medecin.asObservable();
    }
    get chefIME$(): Observable<IChefIME>{
        return this._chefIME.asObservable();
    }
    get chefAcademy$(): Observable<IChefAcademy>{
        return this._chefAcademy.asObservable();
    }
    get manager$(): Observable<IManager>{
        return this._manager.asObservable();
    }
    get headerInfo$(): Observable<IHeaderInfo>{
        return this._headerInfo.asObservable();
    }

    get chefService$(): Observable<IChefService>{
        return this._chefService.asObservable();
    }

    // setters
    set user(value: IPatient){
        this._user.next(value);
    }
    set patient(value: IPatient){
        this._patient.next(value);
    }
    set keycloakProfile(value: KeycloakProfile){
        this._keycloakProfile.next(value);
    }
    set userProfile(value: IAuthResponse){
        this._userProfile.next(value);
    }
    set chefIME(value: IChefIME){
        this._chefIME.next(value);
    }
    set chefAcademy(value: IChefAcademy){
        this._chefAcademy.next(value);
    }
    set medecin(value: IMedecin){
        this._medecin.next(value);
    }
    set manager(value: IManager){
        this._manager.next(value);
    }
    set headerInfo(value: IHeaderInfo){
        this._headerInfo.next(value);
    }
    set chefService(value: IChefService){
        this._chefService.next(value);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getUserProfile(): Observable<IAuthResponse>{
        const userProfile$ = this._authService.getUserConnectedInfo();
        return from(userProfile$)
        .pipe(
            tap((userProfile)=>{
                this._userProfile.next(userProfile);
            })
        );
    }


    loadMedecinData(phone: string): Observable<IHospitalService[]> {
        let medecin: IMedecin = {};
        return this._medecinService.fetchMedecinByPhone(phone)
        .pipe(
            switchMap((data) => {
                medecin = data;
                return this._hospitalService.fetchHospitalById(medecin?.codeFavoris);
            }),
            switchMap((data) => {
                medecin.etablissementFavoris = data;
                return this._coreHospitalServiceService.fetchServiceByMedecinAndHospital(medecin?.id, medecin?.codeFavoris);
            }),
            tap((data: IHospitalService[]) => {
                if(data?.length) {
                    medecin.service = data[0];
                }
                this._medecin.next(medecin);
                this.headerInfo = {
                    ...this._headerInfo.value,
                    organisationId: medecin.codeFavoris,
                    organisationName: 'HOSPITAL',
                    profilId: 'MEDECIN',
                    profilName: 'MEDECIN',
                };
            }),
        );
    }

    loadChefIMEData(phone: string): Observable<IChefIME> {
        let chefIME: IChefIME;
        return this._chefIMEService.fetchChefIMEByPhone(phone)
        .pipe(
            switchMap((data) => {
                chefIME = data;
                return this._hospitalService.fetchHospitalById(data?.codeEtablissement);
            }),
            tap((data) => {
                chefIME.etablissementDTO = data;
                this._chefIME.next(chefIME);
                this.headerInfo = {
                    ...this._headerInfo.value,
                    organisationName: 'HOSPITAL',
                    profilId: 'CHEF_ETAB_SANITAIRE',
                    profilName: 'CHEF_ETAB_SANITAIRE',
                };
            }),
        );
    }


    loadChefServiceData(phone: string): Observable<IChefService> {
        let chefService: IChefService;
        return this._coreChefService.fetchByPhone(phone)
        .pipe(
            switchMap((data) => {
                chefService = data[0];

                if (data[0]?.etablissementServiceDTO?.codeService) {
                    return this._coreHospitalServiceService.fetchServiceById(data[0]?.etablissementServiceDTO.codeService);
                }
                return of(null);
            }),
            tap((data) => {
                chefService.etablissementServiceDTO.service = data;
                this._chefService.next(chefService);
                this.headerInfo = {
                    ...this._headerInfo.value,
                    organisationName: 'HOSPITAL',
                    profilId: 'CHEF_SERVICE',
                    profilName: 'CHEF_SERVICE',
                };
            }),
        );
    }


    loadChefAcademyData(phone: string): Observable<IChefAcademy> {
        let chefAcademy: IChefAcademy;
        return this._chefAcademyService.fetchChefAcademyByPhone(phone)
        .pipe(
            switchMap((data) => {
                chefAcademy = data;
                if (data?.etablissementScolaire?.codeIME) {
                    return this._hospitalService.fetchHospitalById(data?.etablissementScolaire?.codeIME);
                }
                return of(null);
            }),
            tap((data) => {
                chefAcademy.etablissement = data;
                this._chefAcademy.next(chefAcademy);
                this.headerInfo = {
                    ...this._headerInfo.value,
                    organisationId: chefAcademy.codeEtablissementScolaire,
                    organisationName: 'SCHOOL',
                    profilId: 'CHEF_ETAB_SCOLAIRE',
                    profilName: 'CHEF_ETAB_SCOLAIRE',
                };
            }),
        );
    }

    loadManager(phone: string): Observable<IManager> {
        let manager: IManager;
        return this._coreManagerService.fetchByPhone(phone)
        .pipe(
            switchMap((data) => {
                manager = data;
                if (data?.codeEtablissement) {
                    return this._hospitalService.fetchHospitalById(data?.codeEtablissement);
                }
                return of(null);
            }),
            tap((data) => {
                manager.etablissement = data;
                this.manager = manager;
                this.headerInfo = {
                    ...this._headerInfo.value,
                    organisationId: manager.codeEtablissement,
                    organisationName: 'HOSPITAL',
                    profilId: 'GESTIONNAIRE',
                    profilName: 'GESTIONNAIRE',
                };
            }),
        );
    }

    getRole(userProfile: IAuthResponse): ProfilId {
        if (userProfile?.patient?.attributes?.profilId?.length) {
            return userProfile?.patient?.attributes?.profilId[0].profilName;
        }
        return null;
    }

    getPhone(userProfile: IAuthResponse): string {
        if (userProfile?.patient?.attributes?.telephone?.length) {
            return userProfile?.patient?.attributes?.telephone[0].value;
        }
        return null;
    }

    loadProfile(patient: IPatient): Observable<any> {
        this._patient.next(patient);
        this._user.next(patient);
        this.headerInfo = {...this._headerInfo.value};
        const role = this.getRole(this._userProfile.value);
        let phone = '';
        if(role === 'ADMIN') {
            phone = mockAdmin.telephone.value;
            this.headerInfo = {
                ...this._headerInfo.value,
                organisationId: 'medsen',
                organisationName: 'OWNER',
                profilId: 'ADMIN',
                profilName: 'ADMIN',
            };
        } else {
            phone = this.getPhone(this._userProfile.value);
        }
        if (phone) {
            if(role === 'MEDECIN') {
                return this.loadMedecinData(phone);
            }
            if(role === 'CHEF_ETAB_SANITAIRE' || role ===  'CHEF_IME') {
                return forkJoin([
                    this.loadMedecinData(phone),
                    this.loadChefIMEData(phone)
                ]);
            }
            if(role === 'CHEF_ETAB_SCOLAIRE') {
                return this.loadChefAcademyData(phone);
            }
            if(role === 'GESTIONNAIRE') {
                return this.loadManager(phone);
            }
            if(role === 'CHEF_SERVICE') {
                return forkJoin([
                    this.loadMedecinData(phone),
                    this.loadChefServiceData(phone)
                ]);
            }
        }
        return of(patient);
    }

    get(): Observable<any> {
        return this.getUserProfile()
            .pipe(
                take(1),
                switchMap((userProfile) => {
                    const role = this.getRole(userProfile);
                    const patient: IPatient = userProfile.patient;

                    if (role && role === 'ADMIN') {
                        return of(mockAdmin);
                    }

                    if (!patient) {
                        return of(null);
                    }

                    return of(patient);
                }),
                take(1),
                switchMap((patient: IPatient) => this.loadProfile(patient))
            );
    }


    initState(): void {
        this._user.next(null);
        this._userProfile.next(null);
        this._medecin.next(null);
        this._chefIME.next(null);
        this._chefAcademy.next(null);
    }

    getRedirectUrl(): Observable<string> {
        const roleRedirectMap = {
            'CHEF_ETAB_SANITAIRE': '/app/dashboard/medecin',
            'CHEF_IME': '/app/dashboard/medecin',
            'CHEF_SERVICE': '/app/dashboard/medecin',
            'MEDECIN': '/app/dashboard/medecin',
            'CHEF_ETAB_SCOLAIRE': '/app/dashboard/chef-academy',
            'ADMIN': '/app/dashboard/admin',
            'GESTIONNAIRE': '/app/manager/home'
        };

        return this.patient$.pipe(
            take(1),
            map(user => roleRedirectMap[user.profilId.profilId] || '/app/dashboard/school')
        );
    }
}
