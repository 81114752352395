import { IHospitalService, IService } from 'app/shared/interfaces';

export const mockIService: IService[] = [
    {
        id: '0',
        nomService: 'Médecine générale',
        icon: 'local_hospital',
        active: true,
    },
    {
        id: '1',
        nomService: 'Pédiatrie',
        icon: 'child_care',
        active: true,
    },
    {
        id: '2',
        nomService: 'Cardiologie',
        icon: 'favorite',
        active: true,
    },
    {
        id: '3',
        nomService: 'Orthopédie',
        icon: 'local_hospital',
        active: true,
    },
    {
        id: '4',
        nomService: 'Gynécologie',
        icon: 'pregnant_woman',
        active: true,
    },
    {
        id: '5',
        nomService: 'Dermatologie',
        icon: 'local_hospital',
        active: true,
    },
    {
        id: '6',
        nomService: 'Neurologie',
        icon: 'local_hospital',
        active: true,
    },
    {
        id: '7',
        nomService: 'Ophtalmologie',
        icon: 'visibility',
        active: true,
    },
    {
        id: '8',
        nomService: 'Psychiatrie',
        icon: 'local_hospital',
        active: true,
    },
    {
        id: '9',
        nomService: 'Oncologie',
        icon: 'local_hospital',
        active: true,
    },
    {
        id: '10',
        nomService: 'Néonatologie',
        icon: 'local_hospital',
        active: true,
    },
    {
        id: '11',
        nomService: 'Maternité',
        icon: 'local_hospital',
        active: true,
    },
    {
        id: '12',
        nomService: 'Diabétologie',
        icon: 'local_hospital',
        active: true,
    },
];

export const mockIHospitalService: IHospitalService[] = [

    {
        'id': '66EA2D7019984F29996E92E470E71DA6ETAB-SERV',
        'codeEtablissement': '9B438B1F24E44DECA17DE45785154D3EETAB',
        'codeService': '944138469BD8448DAA43C3765D1D7634SERVC',
        'etablissement': {
            'id': '9B438B1F24E44DECA17DE45785154D3EETAB',
            'nom': 'IME Dakar',
            'nonGoogle': 'IME de Dakar',
            'address': {'address':'Foire'},
            'codeRegion': 'Dakar',
            'codeType': '16EC7921287E4515ADDDF152F3CDAE6CIME',
            'active': true,
            'userId': '3befe6b4-9f8a-4b50-b995-ec213d22b0c0',
            'dateCreate': '2023-04-28T13:09:17.672Z',
            'dateUpdate': '2023-04-28T13:09:17.672Z'
        },
        'service': {
            'id': '944138469BD8448DAA43C3765D1D7634SERVC',
            'nomService': 'Médecine générale',
            'active': true,
            'userId': '3befe6b4-9f8a-4b50-b995-ec213d22b0c0',
            'dateCreate': '2023-04-28T13:10:06.628Z',
            'dateUpdate': '2023-04-28T13:10:06.628Z'
        },
        'active': false,
        'userId': '99c8b30f-7797-45c5-88ae-f4134d666a3a',
        'dateCreate': '2023-09-12T15:58:47.586Z',
        'dateUpdate': '2023-09-12T15:58:47.586Z'
    },
    {
        id: '1',
        codeEtablissement: 'codeEtablissement',
        active: false,
        etablissement: null,
        codeService: mockIService[0].id,
        service: mockIService[0],
    },
    {
        id: '2',
        codeEtablissement: 'codeEtablissement',
        active: false,
        etablissement: null,
        codeService: mockIService[1].id,
        service: mockIService[1],
    },
    {
        id: '3',
        codeEtablissement: 'codeEtablissement',
        active: false,
        etablissement: null,
        codeService: mockIService[2].id,
        service: mockIService[2],
    },
    {
        id: '4',
        codeEtablissement: 'codeEtablissement',
        active: false,
        etablissement: null,
        codeService: mockIService[4].id,
        service: mockIService[4],
    },
    {
        id: '5',
        codeEtablissement: 'codeEtablissement',
        active: false,
        etablissement: null,
        codeService: mockIService[9].id,
        service: mockIService[9],
    },
    {
        id: '6',
        codeEtablissement: 'codeEtablissement',
        active: false,
        etablissement: null,
        codeService: mockIService[2].id,
        service: mockIService[2],
    },
    {
        id: '7',
        codeEtablissement: 'codeEtablissement',
        active: false,
        etablissement: null,
        codeService: mockIService[8].id,
        service: mockIService[8],
    }
];
