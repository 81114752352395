import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IChefAcademy } from 'app/shared/interfaces';
import { environment } from 'environments/environment';
import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CoreChefAcademyService {

    constructor(
        private _httpClient: HttpClient,
    ) { }

    fetchAllChefAcademies(): Observable<IChefAcademy[]> {
        const endpoint = '/query/chefEtabScolaires/allChefEtabScolaires';
        return this._httpClient.get<IChefAcademy[]>(
            environment.query.etablissementScolaire + endpoint
        );
    }


    fetchChefAcademies(idSchool: string): Observable<IChefAcademy[]> {
        const endpoint = '/query/chefEtabScolaires/getByEtablissementScolaire/';
        return this._httpClient.get<IChefAcademy[]>(
            environment.query.etablissementScolaire + endpoint + idSchool
        );
    }


    fetchChefAcademyByPhone(phone: string): Observable<IChefAcademy> {
        const endpoint = '/query/chefEtabScolaires/getByPhone/';
        return this._httpClient.get<IChefAcademy[]>(
            environment.query.etablissementScolaire + endpoint + phone
        ).pipe(
            map((d: IChefAcademy[]) => [...d].sort((a,b) => new Date(b.dateCreate).getTime() - new Date(a.dateCreate).getTime())),
            map(d => d[0]),
        );
    }


    fetchChefAcademyByIdPatient(idPatient: string): Observable<IChefAcademy> {
        const endpoint = '/query/chefEtabScolaires/getByPatient/';
        return this._httpClient.get<IChefAcademy[]>(
            environment.query.etablissementScolaire + endpoint + idPatient
        ).pipe(
            map((d: IChefAcademy[]) => [...d].sort((a,b) => new Date(b.dateCreate).getTime() - new Date(a.dateCreate).getTime())),
            map(d => d[0]),
        );
    }


    postChefAcademy(payload: IChefAcademy): Observable<IChefAcademy> {
        const endpoint = '/commands/chefEtabScolaires/createAndMap/';
        return this._httpClient.post<IChefAcademy>(
            environment.commande.etablissementScolaire + endpoint + payload.codeEtablissementScolaire,
            payload
        );
    }

    archiveChefAcademyByHospital(chefAcademyId: string): Observable<boolean> {
        const endpoint = `/commands/chefEtabScolaires/archive/${chefAcademyId}`;
        return this._httpClient.get<boolean>(
            environment.commande.etablissementScolaire + endpoint,
        );
    }
}
