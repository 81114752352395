import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, take, map } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from '../user/user.service';
import { medecinNavigation } from './data/medecin';
import { chefIMENavigation } from './data/chefIME';
import { academyNavigation } from './data/academy';
import { adminNavigation } from './data/admin';
import { chefEtabNavigation } from './data/chefEtab';
import { ProfilId } from 'app/shared/interfaces';
import { managerNavigation } from './data/manager';
import { chefServiceNavigation } from './data/chefService';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);


    constructor(
        private _userService: UserService,
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    getNavigation(): Observable<Navigation> {
        return this._userService.patient$.pipe(
            take(1),
            map((user) => {
                const navigation = {
                    compact: [],
                    default   : [],
                    futuristic: [],
                    horizontal: []
                };
                if (user) {
                    const role = (user?.profilId?.profilId as ProfilId) || null;
                    if (role === 'MEDECIN') {
                        navigation.compact = navigation.default = medecinNavigation;
                    }
                    if (role === 'CHEF_IME') {
                        navigation.compact = navigation.default = chefIMENavigation;
                    }
                    if (role === 'CHEF_ETAB_SANITAIRE') {
                        navigation.compact = navigation.default = chefEtabNavigation;
                    }
                    if (role === 'ADMIN') {
                        navigation.compact = navigation.default = adminNavigation;
                    }
                    if (role === 'CHEF_ETAB_SCOLAIRE') {
                        navigation.compact = navigation.default = academyNavigation;
                    }
                    if (role === 'GESTIONNAIRE') {
                        navigation.compact = navigation.default = managerNavigation;
                    }
                    if (role === 'CHEF_SERVICE') {
                        navigation.compact = navigation.default = chefServiceNavigation;
                    }
                }
                this._navigation.next(navigation);
                return navigation;
            })
        );
    }

}
