import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPatient } from 'app/shared/interfaces';
import { IMetadata } from 'app/shared/interfaces/metadata';
import { ICachet, ISignature } from 'app/shared/interfaces/signature';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CorePatientService {

    constructor(
        private _httpClient: HttpClient,
    ) { }


    fetchMedcinPatients(idIMedecin: string): Observable<IPatient[]>{
        const endPoint = '/query/patients/getPatientsByMedecin/';
        return this._httpClient.get<IPatient[]>(
            environment.query.patient + endPoint + idIMedecin
        );
    }

    fetchPatientById(id: string): Observable<IPatient>{
        const endpoint = '/query/patients/getById/';
        return this._httpClient.get<IPatient>(
            environment.query.patient + endpoint + id
        );
    }

    fetchDossierPatient(id: string): Observable<IPatient>{
        const endpoint = '/query/dossierPatients/getDossierPatientByPatient/';
        return this._httpClient.get<IPatient>(
            environment.query.patient + endpoint + id
        );
    }

    fetchPatients(): Observable<IPatient[]>{
        const endPoint = '/query/patients/allPatients/';
        return this._httpClient.get<IPatient[]>(environment.query.patient + endPoint);
    }

    fetchPatientByPhone(phone: string): Observable<IPatient> {
        const endpoint = '/query/patients/getByTelephone/';
        return this._httpClient.get<IPatient>(
            environment.query.patient + endpoint + phone
        );
    }

    fetchByService(id: string): Observable<IPatient[]> {
        const endpoint = '/query/patients/findPatientsByEtablissementService/';
        return this._httpClient.get<IPatient[]>(
            environment.query.patient + endpoint + id
        );
    }

    fetchByHospital(idHospital: string): Observable<IPatient[]> {
        const endpoint = '/query/patients/getPatientsByEtablissement/';
        return this._httpClient.get<IPatient[]>(
            environment.query.patient + endpoint + idHospital
        );
    }


    postPatient(payload: IPatient): Observable<IPatient> {
        const endpoint = '/commands/patients/confirm';
        return this._httpClient.post<IPatient>(
            environment.commande.patient + endpoint,
            payload
        );
    }

    postPatients(payload: IPatient[]): Observable<IPatient[]> {
        const endpoint = '/commands/patients/confirmAnArray';
        return this._httpClient.post<IPatient[]>(
            environment.commande.patient + endpoint,
            payload
        );
    }

    putPatient(payload: IPatient): Observable<IPatient> {
        const endpoint = '/commands/patients/update';
        return this._httpClient.put<IPatient>(
            environment.commande.patient + endpoint,
            payload
        );
    }


    //patient medecin
    fetchmetaDataPatients(idPatientMedecin: string): Observable<IMetadata>{
        const endPoint = '/query/metaDataPatients/getByPatient/';
        return this._httpClient.get<IMetadata>(
            environment.query.patient + endPoint + idPatientMedecin
        );
    }

    getSignatureMetaDataPatients(idPatientMedecin: string): Observable<ISignature>{
        const endPoint = '/commands/metaDataPatients/getSignature/';
        return this._httpClient.get<ISignature>(
            environment.commande.patient + endPoint + idPatientMedecin
        );
    }

    getTemponMetaDataPatients(idPatientMedecin: string): Observable<ICachet>{
        const endPoint = '/commands/metaDataPatients/addTampon/';
        return this._httpClient.get<ICachet>(
            environment.commande.patient + endPoint + idPatientMedecin
        );
    }

    reinitMetaDataPatients(idPatientMedecin: string): Observable<boolean>{
        const endPoint = '/commands/metaDataPatients/reinitialiser/';
        return this._httpClient.get<boolean>(
            environment.commande.patient + endPoint + idPatientMedecin
        );
    }

    setDefaultTampon(metaDataPatientId: string, keyTampon: string): Observable<boolean>{
        const endPoint = '/commands/metaDataPatients/defaultCachet/';
        return this._httpClient.get<boolean>(
            environment.commande.patient + endPoint + metaDataPatientId + '/' + keyTampon
        );
    }
}
