import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAuthResponse } from 'app/shared/interfaces/auth_response';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private readonly apiUrl = environment.auth.authLink;
    private realm = environment.keycloakConfig.realm;
    private userInfo: IAuthResponse | null = null;

    constructor(private http: HttpClient) {}

    async signIn(username: string, password: string): Promise<IAuthResponse> {
        const response = await firstValueFrom(
            this.http.get<IAuthResponse>(
                `${this.apiUrl}/authentication/loginAndGetUserInfo/${username}/${password}/${this.realm}`
            )
        );

        if (response) {
            localStorage.setItem('authToken', response.access_token);
            localStorage.setItem('refreshToken', response.refresh_token);
            localStorage.setItem('userInfo', JSON.stringify(response));

            this.userInfo = response;
        }

        return response;
    }

    async logout(): Promise<boolean> {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await firstValueFrom(
            this.http.get<boolean>(`${this.apiUrl}/authentication/logout/${refreshToken}/${this.realm}`)
        );

        if (response) {
            localStorage.removeItem('authToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('userInfo');
            this.userInfo = null;
        }

        return response;
    }

    getToken(): string | null {
        return localStorage.getItem('authToken');
    }

    async refreshToken(): Promise<IAuthResponse> {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await firstValueFrom(
            this.http.get<IAuthResponse>(`${this.apiUrl}/authentication/refreshToken/${refreshToken}/${this.realm}`)
        );

        if (response) {
            localStorage.setItem('authToken', response.access_token);
            localStorage.setItem('refreshToken', response.refresh_token);
        }

        return response;
    }

    async check(): Promise<boolean> {
        const token = localStorage.getItem('authToken');
        return !!token && !this.isTokenExpired(token);
    }

    async getUserConnectedInfo(): Promise<IAuthResponse | null> {
        if (this.userInfo) {
            return this.userInfo;
        }

        const userInfo = localStorage.getItem('userInfo');
        if (userInfo) {
            this.userInfo = JSON.parse(userInfo);
            return this.userInfo;
        }

        return null;
    }

    isTokenExpired = (token: string): boolean => {
        try {
            const decodedToken: any = this.parseJwt(token);
            const expiration = decodedToken.exp * 1000;
            return Date.now() > expiration;
        } catch (error) {
            return true;
        }
    };

    parseJwt = (token: string): any => {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
        );

        return JSON.parse(jsonPayload);
    };
}
