import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { MedecinGuard } from './core/guards/medecin/medecin.guard';
import { SchoolGuard } from './core/guards/school/school.guard';
import { AuthGuard } from './core/auth/guards/auth.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboards/project'
    {path: '', pathMatch : 'full', redirectTo: 'sign-in'},

    // Redirect signed in user to the '/dashboards/project'
    // default redirect
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'sign-in'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-in', loadChildren: () => import('app/core/auth/auth.module').then(m => m.AuthModule)},
        ]
    },

    // Admin routes
    {
        path       : '',
        // canActivate: [AuthGuard],
        // canActivateChild: [AuthGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [

            // app
            { path: 'app', children: [
                {
                    path: 'dashboard',
                    loadChildren: () => import('app/modules/dashboard/dashboard.module').then(m => m.DashboardModule)
                },
                {
                    path: 'manager',
                    loadChildren: () => import('app/modules/manager/manager.module').then(m => m.ManagerModule)
                },
                {
                    path: 'school',
                    loadChildren: () => import('app/modules/academy/academy.module').then(m => m.AcademyModule),
                    canActivate: [SchoolGuard],
                },
                {
                    path: 'medecin',
                    loadChildren: () => import('app/modules/medecin/medecin.module').then(m => m.MedecinModule),
                    canActivate: [MedecinGuard],
                },
                {
                    path: 'etablissement',
                    loadChildren: () => import('app/modules/chef-ime/chef-ime.module').then(m => m.ChefIMEModule),
                    //canActivate: [ChefIMEGuard],
                },
                {
                    path: 'setting',
                    loadChildren: () => import('app/modules/settings/settings.module').then(m => m.SettingsModule)
                },
                {
                    path: 'admin',
                    loadChildren: () => import('app/modules/admin/admin.module').then(m => m.AdminModule)
                },
                {
                    path: 'chef-service',
                    loadChildren: () => import('app/modules/chef-sevice/chef-service.module').then(m => m.ChefServiceModule)
                },
            ]},


            // 404 & Catch all
            { path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/error/error-404/error-404.module').then(m => m.Error404Module) },
            { path: '**', redirectTo: '404-not-found' }
        ]
    }
];
