import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMedecin, IMedecinService } from 'app/shared/interfaces';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class CoreMedecinService {

    constructor(
        private _httpClient: HttpClient,
    ) { }

    fetchMedecinById(id: string): Observable<IMedecin> {
        const endpoint = '/query/medecins/getById/';
        return this._httpClient.get<IMedecin>(
            environment.query.medecin + endpoint + id
        );
    }

    fetchMedecinByPhone(phone: string): Observable<IMedecin> {
        const endpoint = '/query/medecins/getByPhone/';
        return this._httpClient.get<IMedecin>(
            environment.query.medecin + endpoint + phone
        );
    }

    fetchMedecinByIdPatient(idPatient: string): Observable<IMedecin> {
        const endpoint = '/query/medecins/getByPatient/';
        return this._httpClient.get<IMedecin>(
            environment.query.medecin + endpoint + idPatient
        );
    }

    fetchMedecinsByHospital(idHospital: string): Observable<IMedecin[]> {
        // const endpoint = '/query/medecins/getAllActivedMedecinsByEtablissement/';
        const endpoint = '/query/medecins/getAllMedecinsByEtablissement/';
        return this._httpClient.get<IMedecin[]>(
            environment.query.medecin + endpoint + idHospital
        );
    }

    fetchMedecinsByService(idService: string): Observable<IMedecin[]> {
        const endpoint = '/query/medecins/getAllMedecinsByService/';
        return this._httpClient.get<IMedecin[]>(
            environment.query.medecin + endpoint + idService
        );
    }

    fetchByManager(idManager: string): Observable<IMedecin[]> {
        const endpoint = '/query/medecins/getAllMedecinsByGestionnaire/';
        return this._httpClient.get<IMedecin[]>(
            environment.query.medecin + endpoint + idManager
        );
    }

    fetchMedecinServicesByMedecin(idMedecin: string): Observable<IMedecinService> {
        const endpoint = '/query/medecinServices/getByMedecin';
        return this._httpClient.get<IMedecinService>(
            environment.query.medecin + endpoint + idMedecin
        );
    }

    fetchMedecinServicesByMedecinAndService(idMedecin: string, idHospitalService: string): Observable<IMedecinService> {
        const endpoint = '/query/medecinServices/getByMedecinAndService';
        return this._httpClient.get<IMedecinService>(
            `${environment.query.medecin}${endpoint}/${idMedecin}/${idHospitalService}`
        );
    }


    postMedecin(payload: IMedecin): Observable<IMedecin> {
        const endpoint = '/commands/medecins/create';
        return this._httpClient.post<IMedecin>(
            environment.commande.medecin + endpoint,
            payload
        );
    }

    postMedecinToHospital(payload: IMedecin, idHospital: string): Observable<IMedecin> {
        const endpoint = '/commands/medecins/createAndMap';
        return this._httpClient.post<IMedecin>(
            `${environment.commande.medecin}${endpoint}/${idHospital}`,
            payload
        );
    }


    postMedecinToHospitalService(payload: IMedecin, idHospital: string, idHospitalService: string): Observable<IMedecin> {
        const endpoint = '/commands/medecins/createAndMapToService';
        return this._httpClient.post<IMedecin>(
            `${environment.commande.medecin}${endpoint}/${idHospital}/${idHospitalService}`,
            payload
        );
    }

    linkMedecinToHospital(idHospital: string, idMedecin: string): Observable<{id: string; codeEtablissement: string; codeMedecin: string}> {
        const endpoint = '/commands/medecinEtablissements/create';
        return this._httpClient.post<{id: string; codeEtablissement: string; codeMedecin: string}>(
            environment.commande.etablissement + endpoint,
            {
                codeEtablissement: idHospital,
                codeMedecin: idMedecin
            }
        );
    }


    // and activate
    linkMedecinToService(idService: string, idMedecin: string, idHospital: string): Observable<any> {
        const endpoint = '/commands/medecinServices/create';
        return this._httpClient.post<any>(
            environment.commande.medecin + endpoint,
            {
                codeService: idService,
                codeMedecin: idMedecin,
                codeEtablissement: idHospital,
            }
        );
    }

    activateMedecinService(idMedecinService: string): Observable<any> {
        const endpoint = '/commands/medecinServices/activated/';
        return this._httpClient.get<any>(
            environment.commande.medecin + endpoint + idMedecinService
        );
    }

    desactivateMedecinService(idMedecinService: string): Observable<any> {
        const endpoint = '/commands/medecinServices/desactived/';
        return this._httpClient.get<any>(
            environment.commande.medecin + endpoint + idMedecinService
        );
    }

    activateMedecinEtab(idEtab: string, idMedecin: string): Observable<boolean> {
        const endpoint = '/commands/medecinEtablissements/actived/';
        return this._httpClient.get<boolean>(
            environment.commande.medecin + endpoint + idEtab + '/' + idMedecin
        );
    }

    desActivateMedecinEtab(idEtab: string, idMedecin: string): Observable<boolean> {
        const endpoint = '/commands/medecinEtablissements/desactived/';
        return this._httpClient.get<boolean>(
            environment.commande.medecin + endpoint + idEtab + '/' + idMedecin
        );
    }

    activateMedecinInService(idEtab: string, idMedecin: string): Observable<boolean> {
        const endpoint = '/commands/medecinServices/actived/';
        return this._httpClient.get<boolean>(
            environment.commande.medecin + endpoint + idEtab + '/' + idMedecin
        );
    }

    desActivateMedecinInService(idEtab: string, idMedecin: string): Observable<boolean> {
        const endpoint = '/commands/medecinServices/desactived/';
        return this._httpClient.get<boolean>(
            environment.commande.medecin + endpoint + idEtab + '/' + idMedecin
        );
    }

    unLinkMedecinToService(idMedecinService: string): Observable<any> {
        const endpoint = '/commands/medecinServices/delete/';
        return this._httpClient.delete<any>(
            environment.commande.medecin + endpoint + idMedecinService
        );
    }

}
