import { FuseNavigationItem } from '@fuse/components/navigation';

export const managerNavigation: FuseNavigationItem[] = [


    {
        id      : 'dashboard',
        title   : '',
        subtitle: '',
        type    : 'group',
        icon    : 'heroicons_outline:chart-pie',
        classes: {
            title: 'text-gray-100'
        },
        children: [
            {
                id      : 'dashboard.home',
                title   : 'Accueil',
                type    : 'basic',
                icon    : 'heroicons_outline:home',
                link : '/app/manager/home',
            },
            {
                id      : 'dashboard.medecin',
                title   : 'Médecins',
                type    : 'basic',
                icon    : 'heroicons_outline:user-group',
                link : '/app/manager/medecin',
            },
            {
                id      : 'dashboard.medical-visit',
                title   : 'Rendez-vous',
                type    : 'basic',
                icon    : 'mat_outline:access_time',
                link: '/app/manager/medical-visit'
            },
            {
                id      : 'medecin.patient',
                title   : 'Patients',
                type    : 'basic',
                icon    : 'heroicons_outline:user-group',
                link: '/app/manager/patient'
            },

            {
                id      : 'configuration',
                title   : '',
                subtitle: '',
                type    : 'group',
                icon    : 'heroicons_outline:cog',
                classes: {
                    title: 'text-gray-100'
                },
                children: [
                    {
                        id      : 'configuration.module',
                        title   : 'Compte',
                        type    : 'basic',
                        icon    : 'heroicons_outline:user',
                        link: '/app/setting'
                    }
                ]
            }
        ]
    },

];
