import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Observable, combineLatest, filter, first, from, interval, of, switchMap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CoreServiceWorkerService {

    promptEvent: any;

    constructor(
        private _appRef: ApplicationRef,
        private _updates: SwUpdate,
    ) { }

    initPwaPrompt(): void {
        window.addEventListener('beforeinstallprompt', (event: any) => {
            event.preventDefault();
            this.promptEvent = event;
          });
    }

    installPWA(): void {
        if (this.shouldInstall()) {
            this.promptEvent.prompt();
        }
    }

    shouldInstall(): boolean {
        return !this.isRunningStandalone() && this.promptEvent;
    }

    isRunningStandalone(): boolean {
        return (window.matchMedia('(display-mode: standalone)').matches);
    }


    checkForUpdate(): Observable<VersionReadyEvent> {
        const isStable$ = this._appRef.isStable.pipe(first(isStable => isStable === true));
        const every6Hours$ = interval(6 * 60 * 60 * 1000);
        const isUnrecoverable$ = this._updates.unrecoverable;
        return combineLatest([isStable$, every6Hours$, isUnrecoverable$])
        .pipe(
            switchMap(() => from(this._updates.checkForUpdate())),
            switchMap((updateFound) => {
                if(updateFound) {
                    return this._updates.versionUpdates;
                }
                return of(null);
            }),
            filter((evt): evt is VersionReadyEvent => evt?.type === 'VERSION_READY'),
        );
    }
}
