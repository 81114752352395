import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakUserDTO } from 'app/shared/interfaces';
import { environment } from 'environments/environment';
import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CoreKeycloakService {

    constructor(
        private _httpClient: HttpClient,
    ) { }

    getUsers(): Observable<KeycloakUserDTO[]> {
        const endpoint = '/admin/realms/MedSen/users';
        return this._httpClient.get<KeycloakUserDTO[]>(
            environment.keycloakConfig.url + endpoint
        );
    }

    getUserById(id: string): Observable<KeycloakUserDTO> {
        const endpoint = '/admin/realms/MedSen/users/';
        return this._httpClient.get<KeycloakUserDTO>(
            environment.keycloakConfig.url + endpoint + id
        );
    }

    getByPhone(phone: string): Observable<KeycloakUserDTO | null> {
        const endpoint = '/admin/realms/MedSen/users';
        const queryParams = {
            username: phone,
        };

        return this._httpClient.get<KeycloakUserDTO[]>(
            environment.keycloakConfig.url + endpoint,
            { params: queryParams }
        )
        .pipe(
            map((users) => {
                if (users.length > 0) {
                    return users[0];
                }
                return null;
            })
        );
    }

    postKeycloakUser(payload: KeycloakUserDTO): Observable<null> {
        const endpoint = '/admin/realms/MedSen/users';
        return this._httpClient.post<null>(
            environment.keycloakConfig.url + endpoint,
            payload
        );
    }

    updatePassword(userId: string, newPassword: string): Observable<void> {
        const endpoint = `/admin/realms/MedSen/users/${userId}/reset-password`;
        const payload = { type: 'password', value: newPassword};
        return this._httpClient.put<void>(
            environment.keycloakConfig.url + endpoint,
            payload
        );
    }

    updateKeycloakUser(userId: string, userUpdate: KeycloakUserDTO): Observable<void> {
        const endpoint = `/admin/realms/MedSen/users/${userId}`;
        return this._httpClient.put<void>(
            environment.keycloakConfig.url + endpoint,
            userUpdate
        );
    }

    deleteUser(userId: string): Observable<any> {
        const endpoint = `/admin/realms/MedSen/users/${userId}`;
        return this._httpClient.delete<any>(
            environment.keycloakConfig.url + endpoint
        );
    }
}
