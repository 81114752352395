import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreServiceWorkerService } from './service-worker.service';

const initializer = (pwaService: CoreServiceWorkerService) => (): void => pwaService.initPwaPrompt();

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            deps: [CoreServiceWorkerService],
            multi: true,
        },
    ]
})
export class ServiceWorkerModule { }
