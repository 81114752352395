import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm, UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertType } from '@fuse/components/alert';
import { AuthService } from 'app/core/auth/services/auth.service';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class SignInComponent implements OnInit {

    @ViewChild('signInNgForm') signInNgForm: NgForm;

    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: 'chargement...'
    };
    signInForm: FormGroup;
    showAlert: boolean = false;
    realm: string = environment.keycloakConfig.realm;

    constructor(
        private _authService: AuthService,
        private _formBuilder: UntypedFormBuilder,
        private _userService: UserService,
    ) { }

    ngOnInit(): void {
        this.signInForm = this._formBuilder.group({
            email: ['', [Validators.required]],
            password: ['', Validators.required],
            rememberMe: [false],
        });
    }

    async signIn(): Promise<void> {
        if (this.signInForm.valid) {
            this.signInForm.disable();

            const email = this.signInForm.value.email;
            const password = this.signInForm.value.password;

            try {
                const response = await this._authService.signIn(email, password);

                if (response && localStorage.getItem('authToken')) {
                    this._userService.get().subscribe();
                    this._userService.getRedirectUrl().subscribe();
                }

            } catch (error) {
                this.signInForm.enable();
                this.alert = {
                    type: 'error',
                    message: 'Utilisateur ou mot de passe incorrect'
                };
                this.showAlert = true;
            }
        }
    }
}
