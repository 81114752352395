import { ProjectMockApi } from 'app/mock-api/dashboards/project/api';
import { ServiceRequestsMockApi } from './serviceRequest/api';

export const mockApiServices = [

    // using
    ProjectMockApi,
    ServiceRequestsMockApi,

];
