import { Component, OnInit } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config';
import { Scheme } from 'app/core/config/app.config';

@Component({
  selector: 'theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss']
})
export class ThemeComponent implements OnInit {
  checked = false;
  constructor(
    private _fuseConfigService: FuseConfigService
  ) { }

  ngOnInit(): void {
  }

  /**
   * Set the scheme on the config
   *
   * @param scheme
   */
  setScheme(scheme: Scheme): void
  {
      this._fuseConfigService.config = {scheme};
  }

  toggle(event: {checked: boolean}) {
    if (event.checked) {
      this.setScheme('dark');
    } else {
      this.setScheme('light');
    }
  }
}
